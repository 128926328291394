export default [{
    header: 'apps',
},
{
    title: 'Users',
    icon: 'UsersIcon',
    children: [
        {
          title: 'User List',
          route: 'apps-users-list'
        },
        {
            title: 'User Verification',
            icon: 'UserCheckIcon',
            route: 'users-verification',
        },
    ],
},
{
    title: 'Companies',
    icon: "LayersIcon",
    children: [
        {
            title: 'All companies',
            route: 'companies-list',
            icon: 'GridIcon',
        },
        {
            title: 'Company categories',
            route: 'companies-categories',
            icon: 'GridIcon',
        },
        {
            title: 'All requests',
            route: 'companies-requests',
            icon: 'GridIcon',
        },
        // {
        //     title: 'Refunds',
        //     route: 'properties-refunds',
        //     icon: 'CreditCardIcon',
        // },
    ]
},
{
    title: 'Properties',
    icon: "LayersIcon",
    children: [
        {
            title: 'Manage',
            route: 'properties-manage',
            icon: 'GridIcon',
        },
        {
            title: 'Categories',
            route: 'properties-categories',
            icon: 'TargetIcon',
        },
        // {
        //     title: 'Refunds',
        //     route: 'properties-refunds',
        //     icon: 'CreditCardIcon',
        // },
    ]
},
{
    title: 'Locations',
    icon: "LayersIcon",
    children: [
        {
            title: 'Countries',
            route: 'locations-countries',
            icon: 'GridIcon',
        },
        {
            title: 'Regions',
            route: 'locations-regions',
            icon: 'GridIcon',
        },
        {
            title: 'Cities',
            route: 'locations-cities',
            icon: 'GridIcon',
        },
        {
            title: 'Neighborhoods',
            route: 'locations-neighborhoods',
            icon: 'GridIcon',
        },
    ]
},
{
    title: 'Transactions',
    icon: "ListIcon",
    children: [
        {
            title: 'All',
            route: 'transactions-backings',
            icon: 'FileTextIcon',
        },
    ]
},
// {
//     title: "Chat",
//     route: "apps-chat",
//     icon: "MessageSquareIcon"
// }
    // {
    //   title: 'API',
    //   icon: 'CodeIcon',
    // },
]